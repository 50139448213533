import { capitalize } from "lodash";
import { FieldUsage } from "types/constants";
import type {
  ActivityGroup,
  ActivityGroupAggregationResultItem
} from "types/model/activity-group";
import type {
  Attendee,
  AttendeeAggregationResultItem
} from "types/model/attendee";
import type { Field, UploadType } from "types/model/field";
import { FieldType } from "types/model/field";
import type { FieldData, FieldFilters } from "types/model/field-data";
import type { FieldOption } from "types/model/field-option";
import type { User, UserAggregationResultItem } from "types/model/user";
import type { Venue } from "types/model/venue";

export const getFieldTypeReadableName = (
  fieldType: FieldType,
  uploadType?: UploadType
): string => {
  switch (fieldType) {
    case FieldType.Text:
      return "Short text";
    case FieldType.Textarea:
      return "Long text";
    case FieldType.Email:
      return "Email";
    case FieldType.Currency:
      return "Currency";
    case FieldType.Number:
      return "Number";
    case FieldType.Integer:
      return "Integer";
    case FieldType.SelectList:
      return "Dropdown";
    case FieldType.CheckboxSingle:
      return "Single checkbox";
    case FieldType.CheckboxMultiple:
      return "Multiple checkbox";
    case FieldType.Venue:
      return "Venue";
    case FieldType.Date:
      return "Date";
    case FieldType.FileUpload:
      return `File upload ${uploadType ? ` - ${capitalize(uploadType)}` : ""}`;
    default:
      return fieldType;
  }
};

export const filterableFieldTypes = [
  FieldType.SelectList,
  FieldType.Venue,
  FieldType.Text,
  FieldType.Email,
  FieldType.CheckboxSingle,
  FieldType.CheckboxMultiple
];

export const getCanFieldBeUsedAsFilter = (fieldType: FieldType): boolean => {
  return filterableFieldTypes.includes(fieldType);
};

export const getCanFieldBeMadeAdminOnly = (fieldUsage: FieldUsage): boolean => {
  return [FieldUsage.User, FieldUsage.Attendee].includes(fieldUsage);
};

export const homePageFilterableFieldTypes = [
  FieldType.SelectList,
  FieldType.CheckboxMultiple,
  FieldType.Venue
];

export const getCanFieldBeUsedAsHomePageFilter = (
  fieldType: FieldType
): boolean => {
  return homePageFilterableFieldTypes.includes(fieldType);
};

export const getIsFieldStatusUpdateDisallowed = (
  internalKey: string
): boolean => {
  return [
    "user_firstName",
    "user_lastName",
    "user_email",
    "attendee_firstName",
    "attendee_lastName",
    "activity_title",
    "activity_venue"
  ].includes(internalKey);
};

export const getIsFieldMakeNotRequiredDisallowed = (
  internalKey: string
): boolean => {
  return [
    "user_firstName",
    "user_lastName",
    "user_email",
    "attendee_firstName",
    "attendee_lastName",
    "activity_title",
    "activity_venue"
  ].includes(internalKey);
};

export const getIsFieldMakeAdminOnlyDisallowed = (
  internalKey: string
): boolean => {
  return [
    "user_firstName",
    "user_lastName",
    "user_email",
    "attendee_firstName",
    "attendee_lastName"
  ].includes(internalKey);
};

export const getCanFieldLabelBeChanged = (
  internalKey: string,
  label: string
): boolean => {
  return !!internalKey && label === getInternalFieldDefaultLabel(internalKey);
};

const getInternalFieldDefaultLabel = (internalKey: string) => {
  switch (internalKey) {
    case "user_firstName":
      return "First name";
    case "user_lastName":
      return "Last name";
    case "user_email":
      return "Email";
    case "attendee_firstName":
      return "First name";
    case "attendee_lastName":
      return "Last name";
    case "attendee_dob":
      return "Date Of Birth";
    case "activity_title":
      return "Title";
    case "activity_subtitle":
      return "Subtitle";
    case "activity_venue":
      return "Venue";
    case "activity_description":
      return "Description";
  }
};

export const getFieldMutableFields = (
  field: Partial<Field>,
  isNew: boolean,
  isUsedForHomeTab: boolean
): string[] => {
  const internalKey = field.internalKey || "";

  return [
    "title",
    "helpText",
    "textareaFormatting",
    "maxFiles",
    "useForAdminFiltering",
    "adminColumnVisible",
    "registerColumnVisible",
    "sessionsBookedReportColumnVisible",
    "sessionsBookedReportFilterVisible",
    "activitySalesReportColumnVisible",
    "activitySalesReportFilterVisible",
    "attendanceReportColumnVisible",
    "attendanceReportFilterVisible",
    "activitySalesByActivityGroupReportColumnVisible",
    "userSubscriptionsListFilterVisible",
    "bookingsExportColumnVisible",
    "lineItemsExportColumnVisible",
    "sessionsBookedExportColumnVisible",
    "activitySalesReportExportColumnVisible",
    "attendanceReportExportColumnVisible",
    ...(field.usage === FieldUsage.Attendee &&
    !["attendee_firstName", "attendee_lastName"].includes(internalKey)
      ? ["appliesToAllActivityGroups", "activityGroups"]
      : []),
    ...(!getIsFieldMakeAdminOnlyDisallowed(internalKey) &&
    getCanFieldBeMadeAdminOnly(field.usage as FieldUsage)
      ? ["adminOnly"]
      : []),
    "activitySalesByActivityGroupReportExportColumnVisible",
    ...(isNew ? ["type", "usage", "uploadType"] : []),
    ...(!isNew ? ["weight"] : []),
    ...(!getIsFieldStatusUpdateDisallowed(internalKey) && !isUsedForHomeTab
      ? ["enabled"]
      : []),
    ...(!getIsFieldMakeNotRequiredDisallowed(internalKey) ? ["required"] : []),
    ...(field.usage === FieldUsage.Activity ? ["useForSiteFiltering"] : [])
  ];
};

export const getDataWithFieldDataAdded = (
  aggregationData: (
    | ActivityGroupAggregationResultItem
    | AttendeeAggregationResultItem
    | UserAggregationResultItem
  )[]
): (Attendee | ActivityGroup | Partial<User>)[] =>
  aggregationData.map(item => {
    const fieldData = item.fieldData.map(fieldDataItem => {
      if (fieldDataItem.dataType === "ref") {
        const valueRef = item.fieldOptionValues.find(fieldOptionValue =>
          fieldOptionValue._id.equals(fieldDataItem.valueRef)
        );
        if (valueRef) {
          fieldDataItem.valueRef = valueRef;
        }
      } else if (fieldDataItem.dataType === "refArray") {
        const valueRefArray = (fieldDataItem.valueRefArray || []).reduce(
          (acc, value) => {
            const fieldOption = item.fieldOptionArrayValues.find(
              (field: FieldOption) => field._id.equals(value)
            );
            return [...acc, fieldOption];
          },
          []
        );

        if (valueRefArray.length) {
          fieldDataItem.valueRefArray = valueRefArray;
        }
      } else if ("venues" in item && fieldDataItem.dataType === "venueRef") {
        const valueRefVenue = item.venues.find(venue =>
          venue._id.equals(fieldDataItem.valueRefVenue)
        );

        if (valueRefVenue) {
          fieldDataItem.valueRefVenue = valueRefVenue;
        }
      }
      fieldDataItem.field = item.fields.find((field: Field) =>
        field._id.equals(fieldDataItem.field)
      );
      return fieldDataItem;
    });

    return {
      ...item,
      fieldData
    };
  });

export const addFieldDataToAttendeesAggregatedData = (
  registerDataAgg: AttendeeAggregationResultItem[]
): AttendeeAggregationResultItem[] => {
  const data = registerDataAgg.map(attendeeDataAggItem => {
    const userInfoFieldsData = attendeeDataAggItem.userFieldData.map(
      fieldDataItem => ({
        ...fieldDataItem,
        field: attendeeDataAggItem.userFields.find((field: Field) =>
          field._id.equals(fieldDataItem.field)
        ),
        ...(fieldDataItem.dataType === "ref" && {
          valueRef: attendeeDataAggItem.userFieldOptionValues.find(
            (field: FieldOption) => field._id.equals(fieldDataItem.valueRef)
          )
        }),
        ...(fieldDataItem.dataType === "refArray" && {
          valueRefArray: (fieldDataItem.valueRefArray || []).reduce(
            (acc, item) => {
              const fieldOption =
                attendeeDataAggItem.userFieldOptionArrayValues.find(
                  (field: FieldOption) => field._id.equals(item)
                );

              return [...acc, fieldOption];
            },
            []
          )
        })
      })
    );

    const attendeeFieldsData = attendeeDataAggItem.fieldData.map(
      fieldDataItem => ({
        ...fieldDataItem,
        field: attendeeDataAggItem.fields.find((field: Field) =>
          field._id.equals(fieldDataItem.field)
        ),
        ...(fieldDataItem.dataType === "ref" && {
          valueRef: attendeeDataAggItem.fieldOptionValues.find(
            (field: FieldOption) => field._id.equals(fieldDataItem.valueRef)
          )
        }),
        ...(fieldDataItem.dataType === "refArray" && {
          valueRefArray: (fieldDataItem.valueRefArray || []).reduce(
            (acc, item) => {
              const fieldOption =
                attendeeDataAggItem.fieldOptionArrayValues.find(
                  (field: FieldOption) => field._id.equals(item)
                );

              return [...acc, fieldOption];
            },
            []
          )
        })
      })
    );

    attendeeDataAggItem.userFieldData = userInfoFieldsData;
    attendeeDataAggItem.fieldData = attendeeFieldsData;

    return attendeeDataAggItem;
  });

  return data;
};

// TODO: combine this with getDataWithFieldDataAdded, addFieldDataToAttendeesAggregatedData and addFieldDataToRegisterAggregatedData
interface GetAggregationDataWithFieldDataAddedData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  aggregationData: any[];
  activityGroupKey?: string;
  userKey?: string;
  attendeeKey?: string;
  activityGroupsKey?: string; // used for multiple activity groups
}

export const getAggregationDataWithFieldDataAdded = ({
  aggregationData,
  activityGroupKey = "activityInfo",
  userKey = "user_info",
  attendeeKey = "attendee_info",
  activityGroupsKey = "activitiesInfo"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: GetAggregationDataWithFieldDataAddedData): any[] => {
  // TODO: types
  const data = aggregationData.map(dataAggItem => {
    if (dataAggItem[activityGroupKey as keyof typeof dataAggItem]) {
      const activityInfoFieldsData = (
        dataAggItem[activityGroupKey as keyof typeof dataAggItem]
          .fieldData as FieldData[]
      ).map(fieldDataItem => ({
        ...fieldDataItem,
        field: dataAggItem.activityGroupFields.find((field: Field) =>
          field._id.equals(fieldDataItem.field)
        ),
        ...(fieldDataItem.dataType === "ref" && {
          valueRef: dataAggItem.activityGroupFieldOptionValues.find(
            (field: Field) => field._id.equals(fieldDataItem.valueRef)
          )
        }),
        ...(fieldDataItem.dataType === "refArray" && {
          valueRefArray: (fieldDataItem.valueRefArray || []).reduce(
            (acc, item) => {
              const fieldOption =
                dataAggItem.activityGroupFieldOptionArrayValues.find(
                  (field: Field) => field._id.equals(item)
                );
              return [...acc, fieldOption];
            },
            []
          )
        }),
        ...(fieldDataItem.dataType === "venueRef" && {
          valueRefVenue: dataAggItem.activityGroupVenueValues.find(
            (venue: Venue) => venue._id.equals(fieldDataItem.valueRefVenue)
          )
        })
      }));
      dataAggItem[activityGroupKey].fieldData = activityInfoFieldsData;
    }

    if (dataAggItem[activityGroupsKey]) {
      for (const item of dataAggItem[activityGroupsKey]) {
        const activityInfoFieldsData = item.fieldData.map(
          (fieldDataItem: FieldData) => ({
            ...fieldDataItem,
            field: dataAggItem.activityGroupFields.find((field: Field) =>
              field._id.equals(fieldDataItem.field)
            ),
            ...(fieldDataItem.dataType === "ref" && {
              valueRef: dataAggItem.activityGroupFieldOptionValues.find(
                (field: Field) => field._id.equals(fieldDataItem.valueRef)
              )
            }),
            ...(fieldDataItem.dataType === "refArray" && {
              valueRefArray: (fieldDataItem.valueRefArray || []).reduce(
                (acc, item) => {
                  const fieldOption =
                    dataAggItem.activityGroupFieldOptionArrayValues.find(
                      (field: Field) => field._id.equals(item)
                    );
                  return [...acc, fieldOption];
                },
                []
              )
            }),
            ...(fieldDataItem.dataType === "venueRef" && {
              valueRefVenue: dataAggItem.activityGroupVenueValues.find(
                (venue: Venue) => venue._id.equals(fieldDataItem.valueRefVenue)
              )
            })
          })
        );

        item.fieldData = activityInfoFieldsData;
      }
    }

    if (dataAggItem[userKey]) {
      const userInfoFieldsData = (
        dataAggItem[userKey].fieldData as FieldData[]
      ).map(fieldDataItem => ({
        ...fieldDataItem,
        field: dataAggItem.userFields.find((field: Field) =>
          field._id.equals(fieldDataItem.field)
        ),
        ...(fieldDataItem.dataType === "ref" && {
          valueRef: dataAggItem.userFieldOptionValues.find((field: Field) =>
            field._id.equals(fieldDataItem.valueRef)
          )
        }),
        ...(fieldDataItem.dataType === "refArray" && {
          valueRefArray: (fieldDataItem.valueRefArray || []).reduce(
            (acc, item) => {
              const fieldOption = dataAggItem.userFieldOptionArrayValues.find(
                (field: Field) => field._id.equals(item)
              );

              return [...acc, fieldOption];
            },
            []
          )
        })
      }));

      dataAggItem[userKey].fieldData = userInfoFieldsData;
    }

    if (dataAggItem[attendeeKey]) {
      const attendeeFieldsData = (
        dataAggItem[attendeeKey].fieldData as FieldData[]
      ).map(fieldDataItem => ({
        ...fieldDataItem,
        field: dataAggItem.attendeeFields.find((field: Field) =>
          field._id.equals(fieldDataItem.field)
        ),
        ...(fieldDataItem.dataType === "ref" && {
          valueRef: dataAggItem.attendeeFieldOptionValues.find((field: Field) =>
            field._id.equals(fieldDataItem.valueRef)
          )
        }),
        ...(fieldDataItem.dataType === "refArray" && {
          valueRefArray: (fieldDataItem.valueRefArray || []).reduce(
            (acc, item) => {
              const fieldOption =
                dataAggItem.attendeeFieldOptionArrayValues.find(
                  (field: Field) => field._id.equals(item)
                );

              return [...acc, fieldOption];
            },
            []
          )
        })
      }));

      dataAggItem[attendeeKey].fieldData = attendeeFieldsData;
    }

    return dataAggItem;
  });

  return data;
};

export const getHasFiltersApplied = (filters: FieldFilters): boolean => {
  return Object.values(filters).some(filterValue => filterValue !== "");
};

export const getCanUserAttendeeFieldBeUsedOnSessionsBookedReport = (
  field: Field
): boolean => {
  const isFieldWithInternalKey = [
    "user_firstName",
    "user_lastName",
    "user_email",
    "attendee_firstName",
    "attendee_lastName"
  ].includes(field.internalKey);

  const reportFieldTypes = [FieldType.Text, FieldType.Textarea]; // Maybe add more later

  return isFieldWithInternalKey || reportFieldTypes.includes(field.type);
};

export const getIsFieldApplicableToActivityGroup = (
  field: Field,
  activityGroupId: string
): boolean => {
  const appliesToAllActivityGroups = field.appliesToAllActivityGroups !== false;

  return (
    appliesToAllActivityGroups ||
    (field.activityGroups || []).includes(activityGroupId)
  );
};
